import { NavigationLinkItem } from 'core/base/model/navigation.model';
import { ReverseMap } from 'core/base/utils/types';

export const ServicePageComponentType = {
  Block: 'page.block',
  Accordion: 'page.accordion',
  AccordionList: 'page.accordion-list',
  UrlList: 'page.url-list',
} as const;

export type ServicePageComponentType = ReverseMap<typeof ServicePageComponentType>;

// Info-page cant exists without adding slug here
export enum ServicePageSlug {
  AboutUs = 'about-us',
  Sitemap = 'sitemap',
  Terms = 'terms',
  PrivacyPolicy = 'privacy-policy',
}

export interface ServicePageComponent {
  id: number;
  type: ServicePageComponentType;
  title: string;
  description: string;
  number?: number;
  links?: NavigationLinkItem[];
}

export interface ServicePageModel {
  name: string;
  slug: ServicePageSlug;
  components: ServicePageComponent[];
}
